import { FC, ReactNode, memo, useEffect, useState } from 'react';
import cx from 'classnames';
import Head from 'next/head';
import styles from './Layout.module.scss';
import { Grid } from 'dss-ui-library';
import { SiteActionType, useSiteContext } from '../SiteContext';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useModalContext } from '../ModalContext';
import { IeHint } from '../IeHint';
import { fetcher, getEnv } from '@ncs-frontend-monorepo/utils';
import { ErrorBoundary } from '../ErrorBoundary';
import { contentApiURL } from '../../utils';
import useSWR from 'swr/immutable';
import { Kameleoon } from '@ncs-frontend-monorepo/tracking';

const FooterSelector = dynamic(() =>
  import('../../components/FooterSelector').then(
    ({ FooterSelector }) => FooterSelector,
  ),
);

const LogoutHeader = dynamic(() =>
  import('../FTTH/LogoutHeader').then(({ LogoutHeader }) => LogoutHeader),
);

const Navigation = dynamic(() =>
  import('../Navigation').then(({ Navigation }) => Navigation),
);

const OrderNavigation = dynamic(() =>
  import('../OrderNavigation').then(({ OrderNavigation }) => OrderNavigation),
);

const ReducedHeader = dynamic(() =>
  import('../FTTH/ReducedHeader').then(({ ReducedHeader }) => ReducedHeader),
);

const MaintenanceInfobar = dynamic(
  () =>
    import('../Maintenance/MaintenanceInfobar').then(
      ({ MaintenanceInfobar }) => MaintenanceInfobar,
    ),
  { ssr: false },
);

export enum NavigationType {
  default = 'Default',
  reduced = 'Reduced',
  order = 'Order',
}

export enum FooterType {
  default = 'Default',
  order = 'Order',
}

export enum BackgroundColor {
  white = 'White',
  grey = 'Grey',
}

type ImageUrl = {
  src?: string;
  alt?: string;
  title?: string;
};

export interface Meta {
  title?: string;
  seoTitle?: string;
  robots?: string;
  canonical?: string;
  description?: string;
  abstract?: string;
  keywords?: string;
  ogDescription?: string;
  ogTitle?: string;
  ogImageUrl?: ImageUrl;
  twitterTitle?: string;
  twitterDescription?: string;
  twitterImageUrl?: ImageUrl;
}
export interface LayoutProps {
  pageId?: number;
  title?: string;
  meta?: Meta;
  showNavigation?: boolean;
  showLogoutHeader?: boolean;
  hasMaintenanceInfo?: boolean;
  navigationType?: NavigationType;
  footerType?: FooterType;
  background?: BackgroundColor;
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = memo(
  ({
    pageId,
    title,
    meta,
    children,
    showNavigation = true,
    showLogoutHeader = false,
    hasMaintenanceInfo = false,
    navigationType = NavigationType.default,
    footerType = FooterType.default,
    background = BackgroundColor.white,
  }) => {
    const router = useRouter();
    const {
      state: { menuOpen },
      dispatch,
    } = useSiteContext();
    const { closeModal } = useModalContext();
    const site = getEnv().SITE;
    const usedTitle = (meta && meta.seoTitle) || (meta && meta.title) || title;
    const pageTitle = (usedTitle ? usedTitle + ' | ' : '') + site;
    const [metaTitle, setMetaTitle] = useState(pageTitle);
    const { data } = useSWR(`${contentApiURL()}window-inactive-pk`, fetcher);

    const updateMetaTitle = () => {
      setMetaTitle(
        document.hidden
          ? data?.content?.props?.headline || pageTitle
          : pageTitle,
      );
    };

    useEffect(() => {
      const handleRouteChange = () => {
        dispatch({ type: SiteActionType.CLOSE_MENU });
        closeModal();
      };
      router.events.on('routeChangeStart', handleRouteChange);
      return () => {
        router.events.off('routeChangeStart', handleRouteChange);
      };
    }, [router.events, closeModal, dispatch]);

    useEffect(() => {
      if (data?.content?.props?.headline) {
        document.addEventListener('visibilitychange', () => {
          updateMetaTitle();
        });
        return () => {
          document.removeEventListener('visibilitychange', updateMetaTitle);
        };
      }
    }, [data]);

    return (
      <div className={styles.page}>
        <Head>
          <title>{metaTitle}</title>
          <meta charSet="utf-8" />
          {pageId && process.env.NODE_ENV != 'production' && (
            <meta name="page-id" content={String(pageId)} />
          )}
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          {meta?.robots && <meta name="robots" content={meta.robots} />}
          {meta?.canonical && <link rel="canonical" href={meta.canonical} />}
          {meta?.description && (
            <meta name="description" content={meta.description} />
          )}
          {meta?.abstract && <meta name="abstract" content={meta.abstract} />}
          {meta?.keywords && <meta name="keywords" content={meta.keywords} />}
          {meta?.ogTitle && <meta property="og:title" content={meta.ogTitle} />}
          {meta?.ogDescription && (
            <meta
              property="og:description"
              content={meta.ogDescription}
              key="ogDescription"
            />
          )}
          {meta?.ogImageUrl && (
            <meta property="og:image" content={meta.ogImageUrl?.src} />
          )}
          {meta?.twitterTitle && (
            <meta name="twitter:title" content={meta.twitterTitle} />
          )}
          {meta?.twitterDescription && (
            <meta
              name="twitter:description"
              content={meta.twitterDescription}
            />
          )}
          {meta?.twitterImageUrl && (
            <meta name="twitter:image" content={meta.twitterImageUrl?.src} />
          )}
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/icons/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/icons/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/icons/favicon-16x16.png"
          />
          <link
            rel="mask-icon"
            href="/icons/safari-pinned-tab.svg"
            color="#5bbad5"
          />
          <link rel="manifest" href="/icons/site.webmanifest" />
          <meta name="msapplication-TileColor" content="#0091fa" />
          <meta
            name="msapplication-config"
            content="/icons/browserconfig.xml"
          />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#ffffff" />
        </Head>
        {showNavigation && (
          <ErrorBoundary area="header">
            <header className={styles.header}>
              {showLogoutHeader && <LogoutHeader />}
              {navigationType === NavigationType.default && <Navigation />}
              {navigationType === NavigationType.order && <OrderNavigation />}
              {navigationType === NavigationType.reduced && <ReducedHeader />}
            </header>
          </ErrorBoundary>
        )}
        <Grid
          fullWidth
          className={cx(styles.content, {
            [styles.contentBlur]: menuOpen,
            [styles[`with${navigationType}Navigation`]]: showNavigation,
            [styles[`withLogoutHeader`]]: showLogoutHeader,
            [styles[`with${background}Background`]]: background,
          })}
        >
          <main>
            <ErrorBoundary area="content">
              {getEnv().MAINTENANCE_MODE === 'enabled' &&
                hasMaintenanceInfo && <MaintenanceInfobar />}
              {children}
            </ErrorBoundary>
          </main>

          <ErrorBoundary area="footer">
            <FooterSelector type={footerType} />
          </ErrorBoundary>
        </Grid>
        <IeHint />
        <Kameleoon />
      </div>
    );
  },
);

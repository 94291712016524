import React, { useEffect, useState } from 'react';
import { Text, Spacer, Button } from 'dss-ui-library';
import styles from './IeHint.module.scss';

// The JS is inline so it runs even when the hydration and initialization of the React App fails
// It it also purposefully written in very primitive JS
const html = `
<script type="application/javascript">
(function () {
  try {
    if (window.sessionStorage && sessionStorage.getItem('ieHint') === 'ignore') {
      return;
    }
  } catch(e) {}

  var ua = window.navigator.userAgent;
  var msie = ua.indexOf('MSIE ');
  var trident = ua.indexOf('Trident/');

  if (msie > 0 || trident > 0) {
    ieHint.style.display = 'block';
    ieHint.getElementsByTagName('button')[0].addEventListener('click', function() {
      ieHint.style.display = 'none';
      sessionStorage.setItem('ieHint', 'ignore');
    });
  }
})();
</script>
`;

export const IeHint: React.FC = () => {
  const [url, setUrl] = useState('');
  useEffect(() => setUrl(window.location.href), []);

  // skip SSR renders
  if (!url) return null;

  return (
    <div
      id="ieHint"
      data-e2e="ieHint"
      className={styles.ieHint}
      style={{ display: 'none' }}
    >
      <div className={styles.box}>
        <Text appearance="t2">Achtung:</Text>
        <Spacer y={4} block>
          <Text appearance="t4">
            Du verwendest eine alte Version des Internet Explorers als Browser.
            Bitte aktualisiere auf{' '}
            <a href={'microsoft-edge:' + url}>Microsoft Edge</a> oder nutze
            einen anderen aktuellen Browser. Dadurch surfst du sicherer im
            Internet und kannst Webseiten ohne Einschränkungen nutzen.
          </Text>
        </Spacer>
        <Button variant="primary" color="blue" e2e="ie-hint-close">
          <Text appearance="t4_2">Schließen</Text>
        </Button>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </div>
  );
};

import React, { Component, ErrorInfo, ReactNode, Suspense } from 'react';
import { LoadingSpinner } from 'dss-ui-library';

interface Props {
  area: 'header' | 'footer' | 'content';
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.warn(
      `ErrorBoundary.componentDidCatch() in ${this.props.area}`,
      error,
      errorInfo,
    );
  }

  public render() {
    const { area, children } = this.props;
    const { hasError } = this.state;

    if (hasError && process.env.NODE_ENV != 'production') {
      return (
        <div>
          <h2>Es kam zu einem Fehler.</h2>
          <button
            type="button"
            onClick={() => this.setState({ hasError: false })}
          >
            Erneut versuchen.
          </button>
        </div>
      );
    }

    return (
      <Suspense fallback={<LoadingSpinner data-e2e={`suspended-${area}`} />}>
        {children}
      </Suspense>
    );
  }
}

export { ErrorBoundary };

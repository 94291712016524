import Script from 'next/script';
import React, { useEffect, useState } from 'react';
import { isNC, useCookieConsentStore } from '@ncs-frontend-monorepo/utils';

// The JS is inline so it runs even when the hydration and initialization of the React App fails
// It it also purposefully written in very primitive JS

declare global {
  interface Window {
    Kameleoon: any;
  }
}
export const Kameleoon = () => {
  const { statistics, marketing } = useCookieConsentStore();
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const internKameleoonLink = isNC() ? 'l7rn4gqfgd' : 'lg0xh2wlmb';

  useEffect(() => {
    if (scriptLoaded) {
      if (statistics && marketing) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        window?.Kameleoon?.API?.Core?.enableLegalConsent('BOTH');
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        window?.Kameleoon?.API?.Core?.disableLegalConsent('BOTH');
      }
    }
  }, [statistics, marketing, scriptLoaded]);

  useEffect(() => {
    const handleKameleoon = () => {
      setScriptLoaded(true);
    };
    window.addEventListener('Kameleoon::Loaded', handleKameleoon);
    return () => {
      window.removeEventListener('Kameleoon::Loaded', handleKameleoon);
    };
  }, []);

  return (
    <>
      <Script
        id="kameleoonInitializer"
        dangerouslySetInnerHTML={{
          __html: `window.kameleoonIframeURL = "/kameleoon.html"
    window.kameleoonLightIframe = false;
    var kameleoonIframeOriginElement = document.createElement("a");
    kameleoonIframeOriginElement.href = kameleoonIframeURL;
    window.kameleoonIframeOrigin = kameleoonIframeOriginElement.origin || (kameleoonIframeOriginElement.protocol + "//" + kameleoonIframeOriginElement.hostname);
    if (location.href.indexOf(window.kameleoonIframeOrigin) != 0)
    {
        window.kameleoonLightIframe = true;
        var kameleoonProcessMessageEvent = function(event)
        {
            if (window.kameleoonIframeOrigin == event.origin && event.data.slice && event.data.slice(0,9) == "Kameleoon")
            {
                window.removeEventListener("message", kameleoonProcessMessageEvent);
                window.kameleoonExternalIFrameLoaded = true;
                if (window.Kameleoon)
                {
                    Kameleoon.Utils.runProtectedScript(event.data);
                    Kameleoon.Analyst.load();
                }
                else
                {
                    window.kameleoonExternalIFrameLoadedData = event.data;
                }
            }
        };
        if (window.addEventListener)
        {
            window.addEventListener("message", kameleoonProcessMessageEvent, false);
        }
        var iframeNode = document.createElement("iframe");
        iframeNode.src = kameleoonIframeURL;
        iframeNode.id = "kameleoonExternalIframe";
        iframeNode.style = "float: left !important; opacity: 0.0 !important; width: 0px !important; height: 0px !important;";
        document.head.appendChild(iframeNode);
    }`,
        }}
      />
      <Script
        id="kameleoonQueue"
        dangerouslySetInnerHTML={{
          __html: `window.kameleoonQueue = window.kameleoonQueue || [];`,
        }}
      />
      <Script
        id="kameleoonScript"
        src={`//${internKameleoonLink}.kameleoon.eu/kameleoon.js`}
        async
      />
    </>
  );
};

export default Kameleoon;
